import React from 'react'

function Footer() {
  return (
    <div className='footer'>
      <p>&copy;Bespoke Web Services | {(new Date().getFullYear())} | All Rights Reserved</p>
    </div>
  )
}

export default Footer