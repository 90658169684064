import React from 'react';
import { Link } from 'react-router-dom';
// import { GiCircleClaws } from "react-icons/gi";

const Home: React.FC = () => (
  <div className='home'>
    <h1><span>Bespoke</span> Web Services</h1>
    <h3>Tailored Web Experiences for Lasting Impressions</h3>
    <div className='flex'>
      <div>
        <h2>Want More Business?</h2>
        <p>
          Transform your online presence with tailored web services designed specifically for small business owners. We
          understand that every business is unique, and we craft websites that reflect your brand, connect with your audience,
          and drive results. Whether you need a simple, elegant website or a powerful e-commerce platform, we’ve got you covered.
        </p>
        <Link className='button get-started' to="/contact">Get Started</Link>
        <Link className='button-alt' to="/about">Learn More</Link>
        <div className='light-circle'>Research shows that having a strong online presence is one of the most important factors to starting a successful business in 2024!</div>
      </div>
      <div className='center'>
        <img src='/media/images/excited.jpg' alt='Small Business Owner' />
      </div>
    </div>
    <div className='flex align-center'>
      <img className='metrics-img' src='/media/images/money.jpg' alt='Metrics' />
      <div>
        <h2>Increase Your Revenue</h2>
        <p className='light-bg'>
          Having a strong online presence can be a gamechanger when it comes to growing your business. With a custom website from  Bespoke Web Services, you can stand out from the competition, attract new customers, and increase your revenue. We have helped countless businesses realize their vision with a revitalized web presence, with sales increasing by more than 60% in some cases! Explore our services and discover how a bespoke website can take your business to the next level!
        </p>
      </div>
    </div>

    <div className='flex'>
      <div className='process'>
        <h2>Our Process</h2>
        <p>
          At Bespoke Web Services, we believe in a transparent and collaborative process to ensure your website meets your needs and exceeds your expectations. From the initial consultation to the final launch, we work closely with you to create a web solution that reflects your brand, connects with your audience, and drives results.
        </p>
        <p>
        Our process is crafted to be efficient, effective, and enjoyable. As a fully remote team, we offer enhanced flexibility, allowing us to dive deep into understanding your business, goals, and challenges. This enables us to create a website experience that’s truly unique to your brand. Together, let’s bring your vision to life and build something remarkable!
        </p>
      </div>
      <img className='process-img' src='/media/images/process.jpg' alt='Process' />
    </div>

    <div className='testimonials'>
      <h2>Testimonials</h2>
      <p>
        Hear what our clients have to say about working with Bespoke Web Services. We take pride in delivering exceptional
        service and results, and we love helping small business owners
        achieve their online goals. We look forward to adding your success story to our list of satisfied clients!
      </p>
      <div className='testimonials-container'>
        <div className='testimonial'>
          <img src='/media/images/customer.jpg' alt='Testimonial' />
          <blockquote>
            <p>
              “Bespoke Web Services helped us create a beautiful website that feels like us. The team was professional, responsive, and a pleasure to work with. We couldn’t be happier with the
              results!”
            </p>
            <cite>- Emma Collins</cite>
          </blockquote>
        </div>
        <div className='testimonial'>
          <img src='/media/images/customer-5.jpg' alt='Testimonial' />
          <blockquote>
            <p>
              "These guys know what they're doing! I highly recommend Bespoke Web Services to any small business owner looking to take their online presence to the next level."
            </p>
            <cite>- William Hayes</cite>
          </blockquote>
        </div>
        <div className='testimonial'>
          <img src='/media/images/customer-7.jpg' alt='Testimonial' />
          <blockquote>
            <p>
              "I'm so happy I decided to invest in a custom website for my business. The team at Bespoke Web Services made the process easy and enjoyable, and the results speak for themselves. My website looks amazing and is already attracting new customers. Thank you!"
            </p>
            <cite>- Amelia Ross</cite>
          </blockquote>
        </div>
      </div>
    </div>

    <div className='get-in-touch'>
      <div>
        <h2>Get in Touch</h2>
        <img src='/media/images/contact-1.jpg' alt='Contact' />
      </div>
      <div>
        <p>
          Ready to take your business to the next level with a bespoke website? We’d love to hear from you! Contact us today to
          discuss your project, ask questions, or learn more about our services. Our team is here to help you achieve your web
          goals and grow your business online.
        </p>
        <p>
          Let’s create something remarkable together. Reach out, and let’s start building a web solution that’s as unique as your
          business.
        </p>
        <Link to="/contact" className='button' style={{ marginRight: "2rem" }}>Get A Quote</Link>
        <Link className='button-alt' to="/about">Learn More</Link>
      </div>
    </div>

    {/* <div className='checkmark'>
      <GiCircleClaws />
    </div> */}
  </div>
);

export default Home;
