import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import { GiCircleClaws } from 'react-icons/gi';

const App: React.FC = () => (
  <div className='app'>
  <Router>
    <nav className='nav'>
      <Link to="/">
        <h1 className='allura-regular'>Bespoke<GiCircleClaws /></h1>
        {/* <h2>Web Services</h2> */}
      </Link>
      <div className='nav-links'>
        <Link to="/">Home</Link> | <Link to="/about">About</Link> | <Link to="/contact">Contact</Link>
      </div>
    </nav>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  </Router>
  <Footer />
  </div>
);

export default App;
