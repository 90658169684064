import React from 'react';
import { Link } from 'react-router-dom';
import { MdComputer } from "react-icons/md";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { MdOutlineScreenSearchDesktop } from "react-icons/md";
import { CiCreditCard1 } from "react-icons/ci";
import { IoImagesOutline } from "react-icons/io5";
import { IoCheckmarkDoneSharp } from "react-icons/io5";

const About: React.FC = () => (
  <div className='about'>
    <h1>About Us</h1>
    <p>
      We’re a dedicated team of web developers and designers passionate about creating bespoke websites for small business owners. With years of experience and a focus on personalized service, we understand the challenges and opportunities unique to emerging businesses.
    </p>
    <p>
      Our mission is to empower business owners with custom websites that are not only beautiful but also highly functional and optimized for growth. We take pride in our consultative approach, ensuring each solution is aligned with your vision, audience, and goals.
    </p>
    <h2>Our Services</h2>
    <div className='our-services'>
      <div className='services'>
        <div>
          <div className='icon-circle'>
            <MdComputer />
          </div>
          <b><p>Custom Web Design</p></b>
        </div>
        <div>
          <div className='icon-circle'>
            <IoPhonePortraitOutline />
          </div>
          <b><p>Responsive Development</p></b>
        </div>
        <div>
          <div className='icon-circle'>
            <MdOutlineScreenSearchDesktop />
          </div>
          <b><p>SEO Optimization</p></b>
        </div>
        <div>
          <div className='icon-circle'>
            <CiCreditCard1 />
          </div>
          <b><p>E-Commerce Solutions</p></b>
        </div>
        <div>
          <div className='icon-circle'>
            <IoImagesOutline />
          </div>
          <b><p>Content Management</p></b>
        </div>
        <div>
          <div className='icon-circle'>
            <IoCheckmarkDoneSharp />
          </div>
          <b><p>And More!</p></b>
        </div>
        <p>
          Whether you’re looking to refresh your current website or build a new online platform from scratch, we’re here to help. Let’s work together to create a web solution that sets your business apart and drives real results.
        </p>
      </div>
    </div>

    <h2>Our Team</h2>
    <div className='our-team align-center'>
      <div>
        <p>
          Our team is made up of experienced web developers, designers, and digital marketing experts dedicated to helping small businesses succeed online.
        <br />
        <br />
          With a focus on collaboration, creativity, and innovation, we’re here to bring your vision to life and deliver a website that exceeds your expectations. Get to know our team and discover how we can help you achieve your web goals.
        </p>
      </div>
      <img src='/media/images/team-2.jpg' alt='Team' />
    </div>

    <div className='get-in-touch'>
      <h2>Get in Touch</h2>
      <p>
        Ready to take your business to the next level with a bespoke website? We’d love to hear from you! Contact us today to
        discuss your project, ask questions, or learn more about our services. Our team is here to help you achieve your web
        goals and grow your business online.
      </p>
      <p>
        Let’s create something remarkable together. Reach out, and let’s start building a web solution that’s as unique as your
        business.
      </p>
      <div>
        <Link to="/contact" className='button'>Contact Us</Link>
      </div>
    </div>
  </div>
);

export default About;
