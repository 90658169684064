import React from 'react';
import { FaEnvelope, FaPhone } from 'react-icons/fa';

const Contact: React.FC = () => (
  <div className='contact'>
    <h1>Contact Us</h1>
    <div className='flex align-center'>
      <div>
        <p>
          Ready to discuss your project? We’d love to hear from you! Whether you have a clear idea or just want to explore the
          possibilities, our team is here to help you build a website that stands out and grows with your business.
          <br />
          <br />
          Let’s create something remarkable together. Reach out, and let’s start building a web experience that’s as unique as
          your business.
        </p>
      </div>
      <img src='/media/images/contact.jpg' alt='Contact' />
    </div>
    <div className='contact-info flex align-center'>
      <div>
        <FaEnvelope /><strong>Email:</strong> bespokewebservices.info@gmail.com <br />
      </div>
      <div>
        <FaPhone /><strong>Phone:</strong> (913) 703-3408
      </div>
    </div>
  </div>
);

export default Contact;
